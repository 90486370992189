/* You can add global styles to this file, and also import other style files */
.lb-title {
  font-weight: 600;
  color: #007741;
  text-transform: uppercase;
}

button.close-lb.light-btn {
  display: inline-block;
  background-color: transparent;
  border: solid 1px #007741;
  color: #007741;
  font-family: "MyriadPro", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  text-decoration: none !important;
  padding: 10px 25px 9px 25px;
  margin: 0 auto;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 3px;
  text-indent: 0;
  text-align: center;
}

.lb-button-wrap {
  gap: 15px;
  display: flex;
  align-items: center;
}

span.input-label {
  padding-left: 5px;
  color: #007741;
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.9);
  overflow: auto;
  pointer-events: none;
  transition: all 0.3s;
}
.modal .modal-content {
  position: relative;
  width: 600px;
  max-width: 100%;
  margin: 20px auto;
  transition: all 0.3s;
  background-color: #f9f9f9;
  border: 1px solid #007741;
  border-radius: 0;
}
.modal .modal-header::after, .modal modal-footer::after {
  color: #007741;
}
.modal .modal-header {
  padding: 15px 20px;
  border-bottom: none;
  justify-content: space-between;
}
.modal .modal-header .modal-title {
  margin: 0;
  float: left;
}
.modal .modal-header .close {
  float: right;
  cursor: pointer;
  font-size: 12px;
}
.modal .modal-footer {
  padding: 15px 20px;
  text-align: center;
  border: none;
}

#modal:checked ~ .modal {
  visibility: visible;
  pointer-events: auto;
}
#modal:checked ~ .modal .modal-content {
  transform: scale(1);
}

input[type=text],
input[type=email],
input[type=password],
select,
input[type=number].custom,
textarea {
  display: inline-block;
  padding: 11px 14px;
  margin: 5px auto 0;
  max-width: 250px;
  width: 100%;
  border: solid 1px #dddddd;
  font-size: 18px;
  font-weight: 300;
  outline: none;
}

.dropdown {
  position: relative;
  display: block;
  margin: 5px 0 0 0;
  width: 100%;
}

.dropdown > input[type=checkbox] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role=button] {
  display: flex;
  justify-content: space-between;
  padding: 12px 14px;
  color: #333;
  text-decoration: none;
  border: 1px solid #ddd;
  cursor: pointer;
  max-width: 265px;
  width: 100%;
}

.dropdown > label:hover,
.dropdown > a[role=button]:hover,
.dropdown > a[role=button]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role=button]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: 6px;
}

.dropdown > ul {
  position: absolute;
  z-index: 999;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  border: 1px solid #8c8c8c;
  background: #fff;
  padding: 6px 0;
  margin: 0;
  list-style: none;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: #333;
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: #ececec;
}

.dropdown > input[type=checkbox]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type=checkbox]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}

.modal-footer.confirm {
  justify-content: center;
}

.modal-header.confirm {
  justify-content: center;
  width: 100%;
}

.modal-header.confirm .close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal.confirm .modal-content {
  width: 400px !important;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

button.delete-btn {
  display: inline-block;
  background-color: #d9534f;
  border: solid 1px #d9534f;
  color: #f9f9f9;
  font-family: "MyriadPro", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  text-decoration: none !important;
  padding: 10px 25px 9px 25px;
  margin: 0 auto;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 3px;
  text-indent: 0;
  text-align: center;
}

button.add-btn {
  display: inline-block;
  background-color: #007741;
  border: solid 1px #007741;
  color: #f9f9f9;
  font-family: "MyriadPro", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  text-decoration: none !important;
  padding: 10px 25px 9px 25px;
  margin: 0 auto;
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 3px;
  text-indent: 0;
  text-align: center;
}

/* this style to blur the background when the spinner is active */
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px); /* Adjust the blur amount as needed */
  z-index: 9999; /* Ensure the spinner is above other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.vpn-dropdown {
  display: inline-block;
  padding: 11px 14px;
  margin: 5px auto 0;
  max-width: 267px;
  width: 100%;
  border: solid 1px #dddddd;
  font-size: 18px;
  font-weight: 300;
  outline: none;
  background-color: #fff;
}

.custom-table > thead > tr > th {
  min-width: 221px !important;
}

.custom-table > tbody > tr > td {
  min-width: 221px !important;
}

.custom-table > tbody > tr > td:nth-child(5) {
  min-width: 221px !important;
}